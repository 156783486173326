import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../css/animate.css"
import "../css/lineicon.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../scss/style.scss"
import "../scss/responsive.scss"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import Jing from "../images/jing3.jpg"
import FeatureStyle2 from "../components/Features/FeatureStyle2"
import TransactionGrid from "../components/Portfolio/TransactionGrid"
import Testimonial from "../components/Testimonial"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import FeatureStyle6 from "../components/Features/FeatureStyle6"
import CallToAction6 from "../components/CallToAction/CallToAction6"
import CallToAction2 from "../components/CallToAction/CallToAction2"
import CallToAction3 from "../components/CallToAction/CallToAction3"
import Zillow from "../images/zillow2.jpg"
import BgImage from "../images/bayarea.jpg"

import CountUp from "react-countup"
import HallOfFame from "../images/hall_of_fame.svg"
import Executive from "../images/executive.svg"
import Chairmans from "../images/chairmans.svg"
import Titan from "../images/titan.svg"
import Img1 from "../images/webinar2.jpg"
import transaction_list from "../components/transaction_list"
import LatestNewsCarousel from '../components/LatestNews/LatestNewsCarousel'
import ReviewTable from '../components/ReviewTable'
import lang from "../components/lang"
import Pleasanton from "../images/pleasanton-008.jpg"
import Dublin from "../images/dublin.jpg"
import SanRamon from "../images/sanramon.jpg"
import Newark from "../images/newark.jpg"
import Fremont from "../images/Fremont.jpg"
import Danville from "../images/Danville.jpg"
import SanJose from "../images/sanjose.jpg"
import Sunnyvale from "../images/Sunnyvale.jpg"
import MountainView from "../images/MountainView.jpg"
import PaloAlto from "../images/palo_alto.jpg"
import Cupertino from "../images/cupertino.jpg"
import Milpitas from "../images/milpitas.jpg"
import Saratoga from "../images/saratoga.jpg"
import RedwoodCity from "../images/redwood.jpg"
import DalyCity from "../images/dalycity.jpg"
import CastroValley from "../images/castrovalley.jpg"
import FosterCity from "../images/fostercity.jpg"
import SanMateo from "../images/sanmateo.jpg"
import Livermore from "../images/livermore.jpg"
import LasVegas from "../images/lasvegas.jpg"
import Orlando from "../images/orlando.jpg"
import Austin from "../images/austin.jpg"
import Campbell from "../images/campbell.jpg"
import Tracy from "../images/tracy.jpg"
import MountainHouse from "../images/mountainhouse.jpg"
import Alameda from "../images/alameda.jpg"
import MenloPark from "../images/menlo_park.jpg"
import LosAltos from "../images/losaltos.jpg"
import LosGatos from "../images/losgatos.jpg"
import Atherton from "../images/atherton.jpg"
import WalnutCreek from "../images/walnutcreek.jpg"
import Millbrae from "../images/millbrae.jpg"
import Burlingame from "../images/burlingame.jpg"
import Ads from "../images/ads.jpg"
import {Helmet} from "react-helmet"

export default () => (
    <>
        <HeaderStyle1 />
        <div className="application">
            <Helmet>
                <script type="application/ld+json">
                {`
                {
                    "@context" : "https://schema.org/",
                    "@type": "EmployerAggregateRating",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name" : "Ratings & reviews for Jing Xue",
                        "sameAs" : "https://www.zillow.com/profile/Jing%20Xue"
                    },
                    "ratingValue": "100",
                    "bestRating": "100",
                    "worstRating": "1",
                    "ratingCount" : "63"
                }
                {
                    "@context" : "https://schema.org/",
                    "@type": "EmployerAggregateRating",
                    "itemReviewed": {
                        "@type": "Organization",
                        "name" : "Google reviews for Jing Xue",
                        "sameAs" : "https://www.google.com/search?q=jing+xue+&client=firefox-b-1-d&sxsrf=AOaemvIOBqITatgae8z72UJD_XPOF2BR-w%3A1634102043733&ei=G2tmYf2QLLWt5OUPy6Gl8AM&ved=0ahUKEwi9it--0MbzAhW1FrkGHctQCT4Q4dUDCA0&uact=5&oq=jing+xue+&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgjECcyBggjECcQEzIFCAAQywEyBQgAEMsBMgUIABDLATIFCAAQywEyBQgAEMsBMgUIABDLATIHCAAQgAQQDEoECEEYAVCsRFisRGC-TmgCcAB4AIABsAKIAbACkgEDMy0xmAEAoAEBwAEB&sclient=gws-wiz#lrd=0x808feb5994a4c681:0xdb249dd99ecaacc9,1,,,"
                    },
                    "ratingValue": "100",
                    "bestRating": "100",
                    "worstRating": "1",
                    "ratingCount" : "24"
                }
                `}
                </script>

            </Helmet>
        </div>

        <section>
          <div className="row">
              <div className="col-md-12">
                  <div className="mb-2">
                      <CallToAction6 bgColor="#222" paddingClass="pad30" text={"Please check out my English website."}/>
                  </div>
              </div>
          </div>
      </section>

        {/* Start Why Choose Us Section Section*/}
        <section className="pad-t30">
            <div className="container">
                <div className="row">
                <h3
                    style={{
                    fontSize: "30px",
                    lineHeight: "40px",
                    margin: "0 0 10px",
                    textTransform: "uppercase",
                    }}
                >
                    {lang === "EN" ? "WHY CHOOSE JING": "为什么选择JING XUE"}
                </h3>
                </div>
                <div className="row">
                <div className="col-md-7">
                    {/*
                    <p>
                    Always committed to providing a high level of service, Jing is known for timely and concise communication, always giving her best to exceed her clients' expectations. As a good listener, Jing will be responsive to all your needs, and guide new and experienced home buyers and sellers through every step of the process, and navigates complex transactions with ease and expertise to deliver results.
                    </p>
                    */}
                    <div className="feature-list" style={{ marginTop: "40px" }}>
                    <div className="row">
                        <div className="col-md-6">
                        <FeatureStyle2
                            icon="icon-trophy"
                            title="湾区Top房产经纪"
                            text="2021,2022,2023成交量过1亿美金，湾区顶级房产经纪人。销量在全美6百多万经纪人中排名164位，三谷成交量第一！"
                        />
                        </div>
                        <div className="col-md-6">
                        <FeatureStyle2
                            icon="icon-ribbon"
                            title="一切为了客户利益"
                            text="精通南湾、东湾、三谷房地产市场！帮客户选择最适合的房子，给客户带来轻松高效的房产交易体验！"
                        />
                        </div>
                        <div className="col-md-6">
                        <FeatureStyle2
                            icon="icon-documents"
                            title="专业解读房检报告"
                            text="全面为客户分析房产优缺点，一对一解析披露报告，让客户的买卖更放心！"
                        />
                        </div>
                        <div className="col-md-6">
                        <FeatureStyle2
                            icon="icon-hotairballoon"
                            title="专业 Zillow 全5星好评"
                            text="记录每一位客户需求，全五星好评，把客户买卖房产当做自己买卖的经纪人！"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="template-image mbl-margin-bottom">
                    <img className="img-fluid" src={Img1} alt=""/>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="template-image text-center">
                    <img src={Jing} alt="" />
                    </div>
                </div>
                </div>
            </div>
        </section>
        {/* End Why Choose Us Section Section*/}

        <LatestNewsCarousel />

        {/* Start Counter Section*/}
        <section
            className="pad-t80 pad-b50 parallax"
            style={{
            backgroundImage: `url(${BgImage})`,
            backgroundPosition: "50% 40px",
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6 ">
                    <div className="animated-counter text-center white">
                        <div className="animated-number">
                        <CountUp end={8} duration={7} />套
                        </div>
                        <h4>十月成交</h4>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                    <div className="animated-counter text-center white">
                        <div className="animated-number">
                        <CountUp end={5} duration={8} />套
                        </div>
                        <h4>十一月成交</h4>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                    <div className="animated-counter text-center white">
                        <div className="animated-number">
                        <CountUp end={2} duration={13} />套
                        </div>
                        <h4>十二月成交</h4>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                    <div className="animated-counter text-center white">
                        <div className="animated-number">
                        <CountUp end={3} duration={12} />套
                        </div>
                        <h4>一月成交</h4>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Counter Section*/}

        {/* Start Welcome Section */}
        <section className="pad-t30 pad-b30">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <SectionTitle title="湾区热门城市" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Pleasanton}
                        title="Pleasanton"
                        text="旧金山东湾最佳学区，明星城市，华人眼中的“幸福屯”"
                        url="Pleasanton"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Dublin}
                        title="Dublin"
                        text="社区优美，发展快速，文化多元的明星之城。"
                        url="Dublin"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={SanRamon}
                        title="San Ramon"
                        text="东湾最安全的好学区，加州抚养孩子最安全的城市。"
                        url="San Ramon"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Newark}
                        title="Newark"
                        text="性价比高，交通方便，年轻工程师的首选安家之地。"
                        url="Newark"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Fremont}
                        title="Fremont"
                        text="整个湾区的中点，一流学区，全美生活最幸福的地方"
                        url="Fremont"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Danville}
                        title="Danville"
                        text="加州传统的好学区、富人区，最适合家庭居住城镇"
                        url="Danville"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={SanJose}
                        title="San Jose"
                        text="硅谷的商业和研发中心，完善的生活配套设施"
                        url="San Jose"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Sunnyvale}
                        title="Sunnyvale"
                        text="高素质的居民，干净明亮的街区，时尚多样的配套"
                        url="Sunnyvale"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={MountainView}
                        title="Mountain View"
                        text="从谷歌诞生地到硅谷核心区"
                        url="Mountain View"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={PaloAlto}
                        title="Palo Alto"
                        text="硅谷核心城市，加州最好的学区"
                        url="Palo Alto"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Cupertino}
                        title="Cupertino"
                        text="Apple总部，硅谷的心脏，顶级学区，湾区中国城"
                        url="Cupertino"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Milpitas}
                        title="Milpitas"
                        text="越来越年轻化的城市，华人工程师的首选"
                        url="Milpitas"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Saratoga}
                        title="Saratoga"
                        text="悠闲安静，临近硅谷，硅谷精英的后花园。"
                        url="Saratoga"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={RedwoodCity}
                        title="Redwood City"
                        text="百万美元的毫宅，无敌海景。"
                        url="Redwood City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={DalyCity}
                        title="Daly City"
                        text=""
                        url="Daly City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={CastroValley}
                        title="Castro Valley"
                        text="地理位置佳、可负担的价位，周边环境风景宜人。"
                        url="Castro Valley"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={FosterCity}
                        title="Foster City"
                        text="风景旖旎的岛居之城，华人置业移民的首选城市之一。"
                        url="Foster City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={SanMateo}
                        title="San Mateo"
                        text="生活便利、交通方便、环境优美。"
                        url="San Mateo"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Livermore}
                        title="Livermore"
                        text="地广人少，风景优美的酒庄，交通方便，房价亲民"
                        url="Livermore"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Campbell}
                        title="Campbell"
                        text="迅猛成长，拥有良好社区，硅谷新宠。"
                        url="Campbell"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Alameda}
                        title="Alameda"
                        text="与世隔绝的滨水城市，又不失生活的便利"
                        url="Alameda"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={MenloPark}
                        title="Menlo Park"
                        text='湾区的新贵族城市'
                        url="Menlo Park"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={LosGatos}
                        title="Los Gatos"
                        text='深受欢迎的富人区，环境优美学区优质'
                        url="Los Gatos"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={LosAltos}
                        title="Los Altos"
                        text='最昂贵和最富有的城市之一，硅谷豪宅区'
                        url="Los Altos"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Atherton}
                        title="Atherton"
                        text='湾区的美国顶级豪宅区，硅谷大佬云集'
                        url="Atherton"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={WalnutCreek}
                        title="Walnut Creek"
                        text='美丽富饶，以白人为主的雅致小城'
                        url="Walnut Creek"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Millbrae}
                        title="Millbrae"
                        text="华人最密集的城市，被誉为美国的“摩纳哥”"
                        url="Millbrae"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Burlingame}
                        title="Burlingame"
                        text="湾区半岛的富人区，典型的中产阶级生活"
                        url="Burlingame"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Ads}
                        title="Jing Xue"
                        text='湾区Top房产经纪'
                        url=""
                        hot={true}
                    />
                    </div>
                </div>
            </div>
        </section>
        {/* End Welcome Section */}
        
        <section>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-5">
                        <CallToAction2 bgColor="#222" paddingClass="pad80" text="联系Jing Xue获取更多湾区城市、学区信息"/>
                    </div>
                </div>
            </div>
        </section>

        {/* Start Welcome Section */}
        <section className="pad-b30">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <SectionTitle title="热门地区房产投资" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={LasVegas}
                        title="Las Vegas"
                        text="美国发展最迅速的城市之一，美国下一个房地产投资热土"
                        url="Las Vegas"
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Orlando}
                        title="Orlando"
                        text="养老、休闲与投资的佳地，美国房市最大的潜力股"
                        url="Orlando"
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Austin}
                        title="Austin"
                        text="经济人口增长远超全美平均水平，高技术公司建立分公司"
                        url="Austin"
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Tracy}
                        title="Tracy"
                        text="远离湾区，生活悠闲，房地产投资的首选城市"
                        url="Tracy"
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={MountainHouse}
                        title="Mountain House"
                        text="新兴开发建设的大型居民社区，充满活力全新社区"
                        url="Mountain House"
                    />
                    </div>
                </div>
            </div>
        </section>
        
        {/* Start Pricing Table Section*/}
        <section className="pad-t60">
            <div className="container">
            <ReviewTable />
            </div>
        </section>
        {/* End Pricing Table Section*/}

        <CallToAction3 />

        {/* Start Portfolio Section*/}
        <section className="pad-t30 pad-b30">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <SectionTitle title={lang === "EN" ? "Jing's Recent Transaction": "近期交易"} />
                </div>
            </div>
            <div className="row">
                <div className="template-image text-center">
                    <img src={Zillow} alt="" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <TransactionGrid transactions={transaction_list}/>
                </div>
            </div>
        </div>
        </section>
        {/* End Portfolio Section*/}

        {/* Start Testimonial Section*/}
        <section className="pad30 parallax" style={{ backgroundColor: "#fff" }}>
        <div className="container">
            <div className="row">
            <div className="col-md-12">
                <SectionTitle title="What People Say" />
            </div>
            </div>
            <div className="row">
            <div className="col-md-6 mbl-margin-bottom">
                <ul className="logo-group width33">
                <li className="client-logo">
                    <a href="/">
                    <img style={{height:"80%", width:"80%"}} src={HallOfFame} alt="Client's Logo" />
                    <div className="designation">2018年度公司销量排名第一</div>
                    </a>
                </li>
                <li className="client-logo">
                    <a href="/">
                    <img style={{height:"80%", width:"80%"}} src={Executive} alt="Client's Logo" />
                    <div className="designation">2019年度公司销量排名第一</div>
                    </a>
                </li>
                <li className="client-logo">
                    <a href="/">
                    <img style={{height:"80%", width:"80%"}} src={Chairmans} alt="Client's Logo" />
                    <div className="designation">2019年度公司销量排名第一</div>
                    </a>
                </li>
                <li className="client-logo">
                    <a href="/">
                    <img style={{height:"80%", width:"80%"}} src={Titan} alt="Client's Logo" />
                    <div className="designation">2020年度公司销量排名第一</div>
                    </a>
                </li>
                </ul>
            </div>
            <div className="col-md-6">
                <Testimonial/>
            </div>
            </div>
        </div>
        </section>
        {/* End Testimonial Section*/}

        {/* Start Footer Section*/}
        <FooterStyle1 />
        {/* End Footer Section*/}
    </>
)
