import React from 'react'

const CallToAction6 = ({bgColor, btnSTyle, paddingClass, text}) => {
    return (
        <section className={`${paddingClass} call-to-action-2`} style={{backgroundColor: `${bgColor}`}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="">
                            <h3 style={{color: "#fff", marginTop: "10px", fontSize: "24px"}}>{text}</h3>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <a href="https://jingxueteam.com/"  target="_blank" className={`btn btn-primary ${btnSTyle}`}>Visit www.jingxueteam.com</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction6
