import React from 'react'
import BgImage from "../../images/bg/bayarea.jpg"
import { Link } from "gatsby"

const CallToAction3 = () => {
    return (
        <section className="pad80 parallax" style={{backgroundImage: `url(${BgImage})`, backgroundPosition: "100% 50%"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="call-to-action text-center white">
                            <h3>湾区房地产市场分析</h3>
                            <p></p>
                            <Link to="/cities" className="btn btn-primary">
                                点击查看
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CallToAction3
